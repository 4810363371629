import { useState, useEffect, useMemo } from "react";
import { Container, Divider } from "@mui/material";
import { ethers } from "ethers";
import copy from "copy-to-clipboard";
import "./comHero.scss";
import ethIcon from "../../assets/image/coins/eth.svg";
import claimIcon from "../../assets/image/coins/claim.png";
import usdtIcon from "../../assets/image/coins/usdt.svg";
import instagramIcon from "../../assets/image/socials/instagram.svg";
import twitterIcon from "../../assets/image/socials/twitter.svg";
import discordIcon from "../../assets/image/socials/discord.svg";
import telegramIcon from "../../assets/image/socials/telegram.svg";
import siktokIcon from "../../assets/image/socials/tiktok.svg";
import { ProgressBar } from "react-rainbow-components";
// import slideLogo from "../../assets/image/preview/slide-logo.svg";
import slideLogo from "../../assets/image/preview/a2.png";

import { getSubString } from "../../util";

import {
  presaleContract,
  usdtContract,
  getProgress,
  zeroAddress,
} from "../../contracts";
import { toBigNum } from "../../utils";
import { NotificationManager } from "react-notifications";
import { useWeb3Modal } from "@web3modal/react";
import { useLocation } from "react-router-dom";
// @ts-ignore
import Fade from "react-reveal/Fade";
// @ts-ignore
import Zoom from "react-reveal/Zoom";
// ----------------------------------------------------------------------

import {
  type WalletClient,
  useWalletClient,
  useAccount,
  useContractWrite,
} from "wagmi";
import { type PublicClient, usePublicClient } from "wagmi";

import { providers } from "ethers";
import { type HttpTransport } from "viem";

import referallIcon from "../../assets/image/referral.png";
import stakeIcon from "../../assets/image/coins/stake.svg";

import Countdown from "react-countdown";

export const ComponentHero = () => {
  return <ComponentHeroContainer />;
};

export function walletClientToSigner(walletClient: WalletClient) {
  const { account, chain, transport } = walletClient;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  const provider = new providers.Web3Provider(transport, network);
  const signer = provider.getSigner(account.address);
  return signer;
}

/** Hook to convert a viem Wallet Client to an ethers.js Signer. */
export function useEthersSigner({ chainId }: { chainId?: number } = {}) {
  const { data: walletClient } = useWalletClient({ chainId });
  return useMemo(
    () => (walletClient ? walletClientToSigner(walletClient) : undefined),
    [walletClient]
  );
}

const ComponentHeroContainer = () => {
  const { open } = useWeb3Modal();
  const { address } = useAccount();

  const [deadline, setDeadline] = useState(new Date("2023-10-31T00:00:00"));

  const [tokenPrice, setTokePrice] = useState(0);
  const [tokenUsdtPrice, setTokeUsdtPrice] = useState(0);
  const [payAmount, setPayAmount] = useState(0);
  const [tokenAmount, setTokenAmount] = useState(0);
  const [tokenUsdtAmount, setTokenUsdtAmount] = useState(0);

  const [claimTokenAmount, setClaimTokenAmount] = useState(0);

  const [referralAddress, setReferral] = useState("");

  const [parentAddress, setParentAddress] = useState(zeroAddress);

  const [isClaim, setClaim] = useState(false);

  const [tapState, setTapState] = useState(1);
  const [progressStatus, setProgressStatus] = useState(0);
  const [totalSaled, setTotalSaled] = useState(0);
  const [isFinished, setIsFinished] = useState(false);
  const location = useLocation();

  const toggleOpen = () => {
    open();
  };

  const countdownComplete = () => {
    setIsFinished(true);
  };

  useEffect(() => {
    const getPrice = async () => {
      let tokenPrice = Number(await presaleContract.ethBuyHelper(1e10)) / 1e10;
      setTokePrice(tokenPrice);

      let tokenUsdtPrice =
        Number(await presaleContract.usdtBuyHelper(1e10)) / 1e10;
      setTokeUsdtPrice(tokenUsdtPrice);

      console.log("token price", tokenPrice);
      console.log("token usdt price", tokenUsdtPrice);
    };
    getPrice();

    const rAddress = new URLSearchParams(location.search).get("address");
    if (rAddress && ethers.utils.isAddress(rAddress))
      setParentAddress(rAddress);
  }, []);

  useEffect(() => {
    const getProgressStatus = async () => {
      const progress = await getProgress();
      console.log("current progress", progress);
      setProgressStatus(progress[0]);
      setTotalSaled(progress[1]);
    };
    getProgressStatus();
  }, []);

  const getClaimTokenAmount = async (address: string) => {
    if (address) {
      console.log(address);
      let tokenAmount =
        Number(await presaleContract.getClaimAmount(address)) / 1e18;
      setClaimTokenAmount(tokenAmount);
    }
  };

  useEffect(() => {
    const getClaimstatus = async () => {
      let status = await presaleContract.getClaimStatus();
      setClaim(status);
    };

    getClaimTokenAmount(address || "");
    getClaimstatus();
    if (address) {
      setReferral(`https://freelancer.com/?address=${address}`);
    } else {
      setReferral("");
    }
  }, [address]);

  const onPayAmountChange = (e: any) => {
    try {
      if (e.target.value === "") {
        setPayAmount(0);
        setTokenAmount(0);
      } else {
        let amount = e.target.value;
        amount = amount.toString().replace(/^0+/, "");
        if (amount.length === 0) amount = "0";
        if (amount[0] === ".") amount = "0" + amount;
        setTokenAmount(amount * tokenPrice);
        setTokenUsdtAmount(amount * tokenUsdtPrice);
        setPayAmount(amount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const signer = useEthersSigner();

  const onBuy = async () => {
    try {
      if (payAmount <= 0) {
        NotificationManager.error("Please input ETH amount");
        return;
      }

      // const prov = new ethers.providers.Web3Provider(provider);
      // let signer = await prov.getSigner();
      if (!signer) return;
      let signedPresaleContract = presaleContract.connect(signer);
      if (tapState === 1) {
        let tx = await signedPresaleContract.buy(parentAddress, {
          value: toBigNum(payAmount, 18),
        });
        await tx.wait();
      } else if (tapState === 2) {
        let signedUSDTContract = usdtContract.connect(signer);
        let tx = await signedUSDTContract.approve(
          presaleContract.address,
          toBigNum(payAmount, 18)
        );
        await tx.wait();
        tx = await signedPresaleContract.buyWithUSDT(
          toBigNum(payAmount, 18),
          parentAddress
        );
        await tx.wait();
      }
      NotificationManager.success("Buy Success");
    } catch (error: any) {
      console.log(error);

      if (error["code"] === "ACTION_REJECTED")
        NotificationManager.error("User Rejected transaction.");
      else NotificationManager.error("Insufficient funds.");
      console.log("error ----------->", error["code"]);
    }
  };

  const onClaim = async () => {
    try {
      if (!isClaim) {
        NotificationManager.error("Claim is not allowed yet.");
        return;
      }
      if (claimTokenAmount <= 0) {
        NotificationManager.error("Please input token amount");
        return;
      }
      if (!signer) return;
      const gasPrice = ethers.utils.parseUnits("3", "gwei");
      let signedPresaleContract = presaleContract.connect(signer);
      let tx = await signedPresaleContract.claimUserToken({ gasPrice });
      await tx.wait();
      NotificationManager.success("Claim Success");
    } catch (error: any) {
      if (error["code"] === "ACTION_REJECTED")
        NotificationManager.error("User Rejected transaction.");
      else NotificationManager.error("Claim Error.");
      console.log("error ----------->", error);
    }
  };

  const copyToClipboard = () => {
    let isCopy = copy(referralAddress);
    //Dispalying notification
    if (isCopy) {
      NotificationManager.success("Copied to Clipboard");
    }
  };

  return (
    <>
      <div className="flex flex-col gap-30">
        <div className="hero-wrapper pt-88">
          <Container maxWidth={"xl"}>
            <div className="flex justify-center lg:flex-row gap-20 mm:justify-between md:pt-100 lg:pt-0 items-center">
              <div className="hidden mm:flex flex-col gap-50 pt-[20px]">
                <Fade left>
                  <h1 className="text-[50px]">
                    <span className="text-thin-blue">YANDER </span> <br />
                    Bringing the fun back to blockchain
                  </h1>
                  <div className="flex flex-col gap-10">
                    <div className="flex flex-col gap-5">
                      <div className="text-[#ffffffcc] flex flex-row gap-10 items-center">
                        <svg
                          width="20"
                          height="15"
                          viewBox="0 0 12 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-[#ffe200]"
                        >
                          <path
                            d="M1 5.375L3.72727 8L11 1"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <p className="text-[16px]">
                          YANDER is the ultimate meme token on the Ethereum
                          chain!
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-5 ">
                      <div className="text-[#ffffffcc] flex flex-row gap-10 items-center">
                        <svg
                          width="20"
                          height="15"
                          viewBox="0 0 12 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-[#ffe200]"
                        >
                          <path
                            d="M1 5.375L3.72727 8L11 1"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <p className="text-[16px]">
                          YANDER offers a fun and interactive experience.
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-5">
                      <div className="text-[#ffffffcc] flex flex-row gap-10 items-center">
                        <svg
                          width="20"
                          height="15"
                          viewBox="0 0 12 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-[#ffe200]"
                        >
                          <path
                            d="M1 5.375L3.72727 8L11 1"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <p className="text-[16px]">
                          YANDER is built on a decentralized blockchain,
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-[160px] h-[46px]">
                    <button className="btn-primary !p-[8px] !bg-[#8fd7a8]">
                      <a
                        className="text-[18px]"
                        href="https://freelancer.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        WhitePaper
                      </a>
                      <svg
                        width="16"
                        height="12"
                        viewBox="0 0 16 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.0012 1L15 6L10.0012 11M1 6H14.86"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </Fade>
              </div>
              <Fade right>
                <div className="buy-box max-w-[500px] w-full flex flex-col gap-[50px] text-black h-[100%]">
                  <div className="flex flex-col gap-[30px] p-[10px] bg-white rounded-lg border-[2px] border-solid boder-[#d4dadf]">
                    <div className="hero-header rounded-lg py-[25px] px-[20px] font-semibold text-center text-white sm:text-[28px] text-[20px]">
                      {!isFinished ? (
                        <p className="flex flex-col gap-[5px] text-white font-sans uppercase">
                          Presale ending in
                          <div>
                            <Countdown
                              date={deadline}
                              onComplete={countdownComplete}
                              className="countdown"
                            />
                          </div>
                        </p>
                      ) : (
                        <div className="rounded-lg p-25 text-20 font-semibold text-center text-white">
                          Presale Discount Ended. Buy Now at Launch Price.
                        </div>
                      )}
                    </div>

                    <div className="flex flex-col gap-[20px] px-[20px]">
                      <div className="font-semibold text-center text-[#01b6f5]">
                        Total Sold: {Math.floor(totalSaled).toLocaleString()}
                        /20,000,000
                      </div>
                      <ProgressBar
                        value={progressStatus}
                        assistiveText="test"
                      />
                      <div className="relative">
                        <div className="px-[30px] text-[15px] font-semibold text-center">
                          1 $SUSOTO = $0.00001
                        </div>

                        <Divider className="absolute w-full top-[50%]" />
                      </div>
                      <div className="grid grid-cols-4 gap-[10px]">
                        <div
                          onClick={() => {
                            setTapState(1);
                          }}
                          className={`col-span-2 cursor-pointer bg-bgLight h-[44px] flex flex-row gap-[5px] items-center p-[5px] rounded-md hover:opacity-75 ${
                            tapState === 1
                              ? "border-[2px] border-[#3980B9]"
                              : ""
                          }`}
                        >
                          <img
                            alt=""
                            src={ethIcon}
                            className="h-[15px] w-[15px] sm:h-[25px] sm:w-[25px]  rounded-full"
                          />
                          <span className="sm:text-[20px] text-[15px] font-bold">
                            ETH
                          </span>
                        </div>

                        <div
                          onClick={() => {
                            setTapState(2);
                          }}
                          className={`col-span-2 first-line:cursor-pointer bg-bgLight h-[44px] flex flex-row gap-[5px] items-center p-[5px] rounded-md hover:opacity-75 ${
                            tapState === 2
                              ? "border-[2px] border-[#3980B9]"
                              : ""
                          }`}
                        >
                          <img
                            alt=""
                            src={usdtIcon}
                            className="h-[15px] w-[15px] sm:h-[25px] sm:w-[25px]  rounded-full"
                          />
                          <span className="sm:text-[20px] text-[15px] font-bold">
                            USDT
                          </span>
                        </div>

                        <div
                          onClick={() => {
                            setTapState(3);
                          }}
                          className={`col-span-2 cursor-pointer bg-bgLight h-[44px] flex flex-row gap-[5px] items-center p-[5px] rounded-md hover:opacity-75 ${
                            tapState === 3
                              ? "border-[2px] border-[#3980B9]"
                              : ""
                          }`}
                        >
                          <img
                            alt=""
                            src={claimIcon}
                            className="h-[15px] w-[15px] sm:h-[25px] sm:w-[25px]  rounded-full"
                          />
                          <span className="sm:text-[20px] text-[15px] font-bold">
                            Claim
                          </span>
                        </div>

                        <div
                          className={`col-span-2 cursor-pointer bg-bgLight h-[44px] flex flex-row gap-[5px] items-center p-[5px] rounded-md hover:opacity-75 ${
                            tapState === 4
                              ? "border-[2px] border-[#3980B9]"
                              : ""
                          }`}
                          onClick={() => {
                            setTapState(4);
                          }}
                        >
                          <img
                            alt=""
                            src={referallIcon}
                            className="h-[15px] w-[15px] sm:h-[25px] sm:w-[25px]  rounded-full"
                          />
                          <span className="sm:text-[20px] text-[15px] font-bold">
                            Referral
                          </span>
                        </div>

                        {/* <div
                          className={`col-span-2 col-start-2 flex justify-center  ${
                            tapState === 5
                              ? "border-[2px] border-[#3980B9]"
                              : ""
                          }`}
                        >
                          <a
                            href="https://freelancer.com"
                            target="_blank"
                            rel="noreferrer"
                            className="pr-[20px] bg-bgLight h-[44px] flex flex-row gap-[5px] items-center p-[5px] rounded-md hover:opacity-75"
                          >
                            <img
                              alt=""
                              src={stakeIcon}
                              className="h-[15px] w-[15px] sm:h-[25px] sm:w-[25px]  rounded-full"
                            />
                            <span className="sm:text-[20px] text-[15px] font-bold">
                              Stake
                            </span>
                          </a>
                        </div> */}
                      </div>

                      <Divider />
                      {tapState < 3 && (
                        <>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-[20px]">
                            <div className="flex flex-col">
                              <div className="flex flex-row justify-between">
                                <span className="text-[15px] opacity-80">
                                  Amount in{" "}
                                  {tapState === 1 ? (
                                    <span className="font-semibold">ETH</span>
                                  ) : (
                                    <span className="font-semibold">USDT</span>
                                  )}{" "}
                                  you pay
                                </span>
                              </div>

                              <div className="relative h-[50px] flex flex-row items-center px-[10px] bg-bgLight rounded-md">
                                <input
                                  value={payAmount}
                                  className="default-input"
                                  onChange={onPayAmountChange}
                                />

                                <div className="absolute right-[10px] w-[30px] h-[30px]">
                                  {tapState === 1 ? (
                                    <img
                                      alt=""
                                      src={ethIcon}
                                      className="w-full h-full"
                                    />
                                  ) : (
                                    <img
                                      alt=""
                                      src={usdtIcon}
                                      className="w-full h-full"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="flex flex-col">
                              <div className="flex flex-row justify-between">
                                <span className="text-[15px] opacity-80">
                                  <span className="font-semibold">
                                    $SUSOTO{" "}
                                  </span>{" "}
                                  you receive
                                </span>
                              </div>

                              <div className="relative h-[50px] flex flex-row items-center px-[5px] bg-bgLight rounded-md">
                                {tapState === 1 ? (
                                  <input
                                    value={tokenAmount.toFixed(3)}
                                    className="default-input"
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    value={tokenUsdtAmount.toFixed(3)}
                                    className="default-input"
                                    readOnly
                                  />
                                )}{" "}
                                <div className="absolute right-[10px] w-[30px] h-[30px]">
                                  <img
                                    alt=""
                                    src={slideLogo}
                                    className="w-full h-full rounded-full"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="text-[15px] text-center">
                            0.015 ETH is reserved for gas. The actual amount
                            used will depend on the network.
                          </div>

                          {address && (
                            <div className="flex flex-row justify-center items-center">
                              <div
                                className="w-[70%] py-[10px] bg-bgBtn text-center rounded-full cursor-pointer hover:opacity-75 select-none"
                                onClick={onBuy}
                              >
                                <span className="text-white text-[15px]">
                                  Buy
                                </span>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      {tapState === 3 && (
                        <>
                          <div className="flex flex-col">
                            <div className="flex flex-row justify-between">
                              <span className="text-[15px] opacity-80">
                                Token Amount that you will can claim.
                              </span>
                            </div>

                            <div className="relative h-[50px] flex flex-row items-center px-[5px] bg-bgLight rounded-md">
                              <input
                                type="number"
                                value={claimTokenAmount}
                                className="default-input flex-1"
                                readOnly
                              />

                              <div className="w-[30px] h-[30px]">
                                <img
                                  alt=""
                                  src={slideLogo}
                                  className="w-full h-full"
                                />
                              </div>
                            </div>
                          </div>

                          {!isClaim && (
                            <div className="text-[13px] text-center text-red-500">
                              Claim is not available before the presale ended
                            </div>
                          )}

                          {address && (
                            <div className="flex flex-row justify-center items-center">
                              <div
                                onClick={onClaim}
                                className={`w-[70%] py-[10px] text-center rounded-full cursor-pointer hover:opacity-75 select-none ${
                                  isClaim ? "bg-bgBtn" : "bg-bgBtn/70"
                                }`}
                              >
                                <span className="text-white text-[15px]">
                                  Claim
                                </span>
                              </div>
                            </div>
                          )}
                        </>
                      )}

                      {tapState === 4 && (
                        <>
                          <div className="flex flex-col">
                            <div className="flex flex-row justify-between">
                              <span className="text-[15px] opacity-80">
                                Your referral code.
                              </span>
                            </div>

                            <div className="relative h-[50px] flex flex-row items-center px-[5px] bg-bgLight rounded-md">
                              <input
                                value={referralAddress}
                                className="default-input flex-1"
                                readOnly
                              />

                              <button
                                onClick={copyToClipboard}
                                className="border-none bg-black text-white px-[10px] py-[5px]"
                              >
                                Copy
                              </button>
                            </div>
                          </div>
                        </>
                      )}

                      {address && (
                        <div className="flex flex-row justify-center items-center">
                          <div
                            onClick={toggleOpen}
                            className="w-[70%] py-[10px] border border-borderColor text-center rounded-full cursor-pointer hover:opacity-75 select-none"
                          >
                            <span className="text-[15px]">
                              {getSubString(address || "")}
                            </span>
                          </div>
                        </div>
                      )}
                      {!address && (
                        <div className="flex flex-row justify-center items-center">
                          <div
                            onClick={toggleOpen}
                            className="w-[70%] py-[10px] bg-bgBtn text-center rounded-full cursor-pointer hover:opacity-75 select-none"
                          >
                            <span className="text-white text-[15px]">
                              Connect Wallet
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="text-[15px] text-center pb-[15px]">
                        Stage 2 price: $0.0000125
                      </div>
                    </div>
                  </div>
                  <SocialsLink />
                </div>
              </Fade>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export const SocialsLink = () => {
  return (
    <div className="flex flex-row gap-10 items-center justify-center">
      <a
        href="https://www.instagram.com/aidogecrypto/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt=""
          src={instagramIcon}
          className="w-40 h-40 cursor-pointer hover:opacity-75"
        />
      </a>
      <a
        href="https://twitter.com/aidogecrypto"
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt=""
          src={twitterIcon}
          className="w-40 h-40 cursor-pointer hover:opacity-75"
        />
      </a>
      <a href="https://discord.gg/QE5PdhbjUY" target="_blank" rel="noreferrer">
        <img
          alt=""
          src={discordIcon}
          className="w-40 h-40 cursor-pointer hover:opacity-75"
        />
      </a>
      <a
        href="https://t.me/AiDogeCryptoOfficial"
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt=""
          src={telegramIcon}
          className="w-40 h-40 cursor-pointer hover:opacity-75"
        />
      </a>
      <a
        href="https://www.tiktok.com/@aidogecrypto"
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt=""
          src={siktokIcon}
          className="w-30 h-30 cursor-pointer hover:opacity-75"
        />
      </a>
    </div>
  );
};
